<i
  [ngClass]="{
    'message-icon': true,
    disabled: isChannelDisabled()
  }"
  [pTooltip]="isChannelDisabled() ? 'Channel not available' : ''"
  (click)="goToChat.emit($event)"
>
  @if (isChannelUnread()) {
    <div class="indicator"></div>
  }
</i>
